import IconLockWithArrows from "components/common/icon-components/IconLockWithArrows";
import IconR500 from "components/common/icon-components/IconR500";
import IconSecurity from "components/common/icon-components/IconSecurity";
import {
  FlexCenter,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { MainParagraph } from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import { styled } from "linaria/react";
import React from "react";

const BenefitsSectionInner = styled(SectionInner)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Column = styled(FlexCenter)`
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const BenfitsCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 1024px) {
    max-width: 560px;
  }
`;

const Benefit = styled.div`
  display: flex;
  gap: 1rem;
`;

const BenefitTitle = styled.h4`
  color: #031933;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.875rem;
  letter-spacing: -0.01375rem;
`;

const benefits = [
  {
    Icon: IconSecurity,
    title: "Create a flexible physical security solution",
    description:
      "Leverage our open cloud management platform and API to create tailored security solutions. Seamlessly integrate existing tools and develop custom applications that address your organization's unique security challenges.",
  },
  {
    Icon: IconR500,
    title: "Scale your physical security with confidence",
    description:
      "Future-proof your operations with a flexible, scalable platform that grows with your organization. Easily add new locations, integrate technologies, and quickly adapt to your changing security needs.",
  },
  {
    Icon: IconLockWithArrows,
    title: "Minimize downtime, maximize security",
    description:
      "Deploy faster and maintain peak performance with our nationwide network of local installers and live US-based support. Reduce implementation time, quickly resolve issues, and ensure continuous protection for your assets.",
  },
];

const BenefitsSection = () => {
  return (
    <SectionContainer>
      <BenefitsSectionInner>
        <Column>
          <StaticImage
            src="./assets/locations.png"
            alt="Rhombus Locations"
            quality={100}
            loading="lazy"
            objectFit="contain"
            placeholder="blurred"
            style={{ maxWidth: "560px" }}
          />
        </Column>
        <Column>
          <BenfitsCol>
            {benefits.map(benefit => {
              const { Icon, title, description } = benefit;
              return (
                <Benefit>
                  <Icon />
                  <TextContainer>
                    <BenefitTitle>{title}</BenefitTitle>
                    <MainParagraph>{description}</MainParagraph>
                  </TextContainer>
                </Benefit>
              );
            })}
          </BenfitsCol>
        </Column>
      </BenefitsSectionInner>
    </SectionContainer>
  );
};

export default BenefitsSection;
