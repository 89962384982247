import React from "react";

const IconLockWithArrows = ({
  color = "#2A7DE1",
  backgroundColor = "white",
  height = "52",
  width = "51",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 51 52"
      fill="none"
    >
      <g clip-path="url(#clip0_23933_17948)">
        <path
          d="M22.0322 46.3713C17.3416 45.5677 13.0487 43.1143 9.94436 39.463C6.80567 35.7713 5.07715 31.0636 5.07715 26.2071C5.07715 21.3507 6.80567 16.6429 9.94436 12.9512C12.3101 10.1686 15.3664 8.08291 18.7627 6.89178L18.2337 7.83239C17.8292 8.55137 18.0832 9.4625 18.801 9.86761C19.5056 10.2688 20.4263 10.0249 20.8329 9.29924L23.075 5.31307C23.4785 4.63453 23.2331 3.68724 22.5511 3.291L18.6309 0.922088C17.9254 0.49576 17.0086 0.722969 16.583 1.4296C16.1575 2.13623 16.3843 3.05453 17.0897 3.48075L17.9614 4.00749C13.9952 5.36148 10.4254 7.77631 7.67287 11.0138C4.07518 15.2456 2.09375 20.6415 2.09375 26.2071C2.09375 31.7728 4.07518 37.1686 7.67297 41.4004C11.2297 45.5839 16.1506 48.3953 21.5292 49.3167C22.3224 49.4586 23.1159 48.9137 23.2511 48.0959C23.3899 47.2825 22.8443 46.5103 22.0322 46.3713Z"
          fill={color}
        />
        <path
          d="M43.3302 11.012C39.7734 6.82847 34.8525 4.01709 29.4739 3.0958C28.6616 2.95685 27.8909 3.50331 27.7521 4.31662C27.6132 5.13003 28.1589 5.9021 28.9709 6.04125C33.6615 6.8448 37.9543 9.29818 41.0587 12.9495C44.1974 16.6412 45.9259 21.3489 45.9259 26.2054C45.9259 31.0618 44.1974 35.7696 41.0587 39.4613C38.6929 42.2439 35.6366 44.3296 32.2404 45.5207L32.7694 44.5801C33.1738 43.8611 32.9198 42.95 32.202 42.5449C31.4842 42.1401 30.5746 42.3943 30.1702 43.1133L27.9281 47.0994C27.5246 47.778 27.7695 48.7253 28.4519 49.1215L32.3722 51.4904C33.0661 51.913 33.992 51.6957 34.4201 50.9829C34.8456 50.2763 34.6188 49.358 33.9134 48.9318L33.0417 48.405C37.008 47.051 40.5778 44.6362 43.3303 41.3987C46.928 37.1668 48.9094 31.7711 48.9094 26.2053C48.9094 20.6395 46.928 15.2438 43.3302 11.012Z"
          fill={color}
        />
        <path
          d="M25.501 11.6641C20.4561 11.6641 16.3518 15.775 16.3518 20.8281V22.1079C14.9798 22.773 14.0312 24.1815 14.0312 25.8077V35.2477C14.0312 37.5127 15.871 39.3554 18.1323 39.3554H32.8695C35.1308 39.3554 36.9705 37.5127 36.9705 35.2477V25.8077C36.9705 24.1814 36.022 22.773 34.65 22.1079V20.8281C34.6502 15.775 30.5459 11.6641 25.501 11.6641ZM25.501 14.6523C28.9008 14.6523 31.6667 17.4228 31.6667 20.8281V21.7001H19.3352V20.8281C19.3352 17.4228 22.1011 14.6523 25.501 14.6523ZM33.9872 35.2477C33.9872 35.865 33.4859 36.3671 32.8696 36.3671H18.1324C17.5161 36.3671 17.0147 35.865 17.0147 35.2477V25.8077C17.0147 25.1904 17.5161 24.6883 18.1324 24.6883H32.8696C33.4859 24.6883 33.9872 25.1904 33.9872 25.8077V35.2477Z"
          fill={color}
        />
        <path
          d="M25.5035 28C24.6796 28 24.0117 28.669 24.0117 29.4941V31.5614C24.0117 32.3866 24.6796 33.0556 25.5035 33.0556C26.3273 33.0556 26.9952 32.3866 26.9952 31.5614V29.4941C26.9952 28.669 26.3273 28 25.5035 28Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_23933_17948">
          <rect
            width={width}
            height={width}
            fill={backgroundColor}
            transform="translate(0 0.707031)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconLockWithArrows;
