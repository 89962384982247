import { CTA_BUTTON_COPY_3 } from "components/data/landing-page-data";
import LandingPage from "components/landing-pages/consultant-lp/LandingPage";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";

export default function ConsultantLp() {
  return (
    <GlobalLayout
      landingPage
      hideFooter
      includeButton
      buttonText={CTA_BUTTON_COPY_3}
      ctaLink="/lp/cta-3/"
      color="var(--blue-100)"
    >
      <title>Rhombus Cameras</title>
      <meta
        name="description"
        content="Discover the power of cloud-managed security cameras."
      />
      <meta name="robots" content="noindex, nofollow" />
      <LandingPage />
    </GlobalLayout>
  );
}
