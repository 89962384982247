import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import React from "react";
import fishEye from "./assets/fisheye.png";
import dr20 from "./assets/dr20.png";
import d20 from "./assets/d20.png";
import alarmMonitoring from "./assets/alarm-monitoring.png";
import { styled } from "linaria/react";
import {
  FancyTitleMed,
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";

const features = [
  {
    imageSrc: fishEye,
    title: "Smart Cameras",
    description:
      "Intelligent cloud cameras that modernize security with easy deployment, built-in analytics, and remote access. Secure your spaces confidently and efficiently.",
  },
  {
    imageSrc: dr20,
    title: "Access Control",
    description:
      "Scalable, centralized access management with remote capabilities. Streamline entry, enhance security, and adapt to your growing needs effortlessly.",
  },
  {
    imageSrc: d20,
    title: "Sensors",
    description:
      "Gain actionable insights with powerful sensors linked to high-resolution video. Monitor your environment in real-time for comprehensive awareness.",
  },
  {
    imageSrc: alarmMonitoring,
    title: "Alarm Monitoring",
    description:
      "24/7/365 protection combining smart alerts with live monitoring services. Get rapid, informed responses to potential threats around the clock.",
  },
];

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  width: 100%;
`;

const FeatureRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.125rem;
  flex-wrap: wrap;
`;

const FeatureCard = styled.div`
  max-width: 450px;
  width: calc((100% / 4) - 3 * 1.125rem);
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  overflow: hidden;
  height: 400px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 1024px) {
    width: calc((100% / 2) - 1.125rem);
  }
  @media (max-width: 600px) {
    width: calc(100%);
  }
`;

const CardImage = styled.div`
  height: 50%;
  width: 100%;
  background: ${props => `url(${props.url})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const CardContent = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 1.25rem;
`;

const FeaturesSection = () => {
  return (
    <SectionContainer style={{ background: "#F6F8F9" }}>
      <SectionInner>
        <ContentContainer>
          <FancyTitleMed style={{ fontWeight: 800 }}>
            Unify your security with an all-in-one solution
          </FancyTitleMed>
          <FeatureRow>
            {features.map(feature => {
              const { imageSrc, title, description } = feature;
              return (
                <FeatureCard>
                  <CardImage url={imageSrc} />
                  <CardContent>
                    <TextContainer>
                      <TitleSmall
                        style={{ fontWeight: "800" }}
                        color="var(--blue-900)"
                      >
                        {title}
                      </TitleSmall>
                      <MainParagraph color="var(--blue-900)">
                        {description}
                      </MainParagraph>
                    </TextContainer>
                  </CardContent>
                </FeatureCard>
              );
            })}
          </FeatureRow>
        </ContentContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default FeaturesSection;
