import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import React from "react";
import caseStudy1 from "./assets/cs-1.png";
import caseStudy2 from "./assets/cs-2.png";
import caseStudy3 from "./assets/cs-3.png";
import { styled } from "linaria/react";
import {
  FancyTitleMed,
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";

const features = [
  {
    imageSrc: caseStudy1,
    title: "Enhancing Laguna Beach Unified’s district security",
    description:
      "Find out how LBUSD enhanced campus safety and streamlined operations by upgrading to Rhombus' cloud-managed security system.",
  },
  {
    imageSrc: caseStudy2,
    title: "Empowering In-Shape's branch growth",
    description:
      "See how In-Shape Family Fitness revolutionized operations across 60+ locations with Rhombus' cloud-based cameras.",
  },
  {
    imageSrc: caseStudy3,
    title: "Reshaping Stance's retail security and loss prevention ",
    description:
      "Explore how Stance, the innovative athleisure brand, minimized its loss prevention and security management across 20+ stores.",
  },
];

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  width: 100%;
`;

const FeatureRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.125rem;
  flex-wrap: wrap;
`;

const FeatureCard = styled.div`
  width: calc((100% / 3) - 2 * 1.125rem);
  max-width: 420px;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  overflow: hidden;
  height: 450px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 1024px) {
    width: calc((100% / 2) - 1.125rem);
  }

  @media (max-width: 768px) {
    width: calc(100%);
  }
`;

const CardImage = styled.div`
  height: 60%;
  width: 100%;
  background: ${props => `url(${props.url})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const CardContent = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 1.25rem;
`;

const CaseStudySection = () => {
  return (
    <SectionContainer style={{ background: "#F6F8F9" }}>
      <SectionInner>
        <ContentContainer>
          <FancyTitleMed style={{ fontWeight: 800 }}>
            Proven security solutions for growing organizations
          </FancyTitleMed>
          <FeatureRow>
            {features.map(feature => {
              const { imageSrc, title, description } = feature;
              return (
                <FeatureCard>
                  <CardImage url={imageSrc} />
                  <CardContent>
                    <TextContainer>
                      <TitleSmall
                        style={{ fontWeight: "800" }}
                        color="var(--blue-900)"
                      >
                        {title}
                      </TitleSmall>
                      <MainParagraph color="var(--blue-900)">
                        {description}
                      </MainParagraph>
                    </TextContainer>
                  </CardContent>
                </FeatureCard>
              );
            })}
          </FeatureRow>
        </ContentContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default CaseStudySection;
