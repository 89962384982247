import React from "react";

const IconQuote = ({ height = "37", width = "48" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 37"
      fill="none"
    >
      <path
        d="M10.9594 36.708C8.84338 36.708 7.00338 36.156 5.43938 35.052C3.87538 33.856 2.67937 32.246 1.85137 30.222C1.02338 28.198 0.609375 25.898 0.609375 23.322C0.609375 20.194 1.11538 17.204 2.12738 14.352C3.23138 11.408 4.88737 8.694 7.09537 6.21C9.30338 3.726 12.1554 1.656 15.6514 0L21.4474 5.382C18.9634 6.21 16.7554 7.36 14.8234 8.832C12.9834 10.304 11.4654 11.96 10.2694 13.8C9.16538 15.64 8.38338 17.526 7.92338 19.458L8.47538 19.734C8.84338 19.458 9.30338 19.228 9.85538 19.044C10.4074 18.86 11.2354 18.768 12.3394 18.768C13.4434 18.768 14.5934 19.09 15.7894 19.734C16.9854 20.378 17.9514 21.344 18.6874 22.632C19.5154 23.828 19.9294 25.438 19.9294 27.462C19.9294 29.118 19.5154 30.636 18.6874 32.016C17.9514 33.396 16.8934 34.546 15.5134 35.466C14.1334 36.294 12.6154 36.708 10.9594 36.708ZM36.9034 36.708C34.7874 36.708 32.9474 36.156 31.3834 35.052C29.8194 33.856 28.6234 32.246 27.7954 30.222C26.9674 28.198 26.5534 25.898 26.5534 23.322C26.5534 20.194 27.0594 17.204 28.0714 14.352C29.1754 11.408 30.8314 8.694 33.0394 6.21C35.2474 3.726 38.0994 1.656 41.5954 0L47.3914 5.382C44.9074 6.21 42.6994 7.36 40.7674 8.832C38.9274 10.304 37.4094 11.96 36.2134 13.8C35.1094 15.64 34.3274 17.526 33.8674 19.458L34.4194 19.734C34.7874 19.458 35.2474 19.228 35.7994 19.044C36.3514 18.86 37.1794 18.768 38.2834 18.768C39.3874 18.768 40.5374 19.09 41.7334 19.734C42.9294 20.378 43.8954 21.344 44.6314 22.632C45.4594 23.828 45.8734 25.438 45.8734 27.462C45.8734 29.118 45.4594 30.636 44.6314 32.016C43.8954 33.396 42.8374 34.546 41.4574 35.466C40.0774 36.294 38.5594 36.708 36.9034 36.708Z"
        fill="url(#paint0_linear_23933_17915)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_23933_17915"
          x1="0.609375"
          y1="18.354"
          x2="47.3914"
          y2="18.354"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2573DD" />
          <stop offset="1" stop-color="#05B7D9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconQuote;
