import IconCheckMark from "components/common/icon-components/IconCheckMark";
import RhombusButton from "components/common/RhombusButton";
import { CTA_BUTTON_COPY_3 } from "components/data/landing-page-data";
import {
  FlexCenter,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import { styled } from "linaria/react";
import React from "react";

const Text = styled(TextContainer)`
  h2,
  & {
    text-align: left;
  }
`;

const BulletList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const bulletPoints = [
  "#1 G2-rated Physical Security system",
  "Backed by our 10-year warranty",
];

const CTASection2 = () => {
  return (
    <SectionContainer style={{ background: "var(--gradient-light-bottom)" }}>
      <SectionInner>
        <FlexCenter>
          <Text>
            <FancyTitleMed style={{ fontWeight: 800 }}>
              Take the next step in modernizing your security infrastructure
            </FancyTitleMed>
            <MainParagraph>
              Experience firsthand how Rhombus can transform your security
              infrastructure with our innovative, cloud-based platform. Schedule
              a personalized demo today and discover how easy it is to
              streamline operations, enhance protection, and future-proof your
              security across all locations.
            </MainParagraph>

            <RhombusButton
              style={{ padding: "1.25rem", margin: "2rem 0" }}
              title={CTA_BUTTON_COPY_3}
              path="/lp/cta-3/"
              useTitleCase={false}
            />
            <BulletList>
              {bulletPoints.map(item => (
                <ListItem>
                  <IconCheckMark color="var(--teal-500)" />
                  {item}
                </ListItem>
              ))}
            </BulletList>
          </Text>
        </FlexCenter>
        <FlexCenter>
          <StaticImage
            src="./assets/product-suite.png"
            alt="Rhombus Access Control for Schools"
            quality={100}
            loading="lazy"
            placeholder="blurred"
            style={{ maxWidth: "560px" }}
          />
        </FlexCenter>
      </SectionInner>
    </SectionContainer>
  );
};

export default CTASection2;
