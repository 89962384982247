import React from "react";

const IconCloudSecurity = ({
  color = "#2A7DE1",
  height = "52",
  width = "51",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 51 52"
      fill="none"
    >
      <path
        d="M39.8135 12.5277C38.962 9.69366 37.2661 7.14256 34.9565 5.25228C32.2988 3.07691 28.9403 1.87891 25.5 1.87891C22.0597 1.87891 18.7012 3.07691 16.0434 5.25238C13.7338 7.14266 12.0379 9.69376 11.1864 12.5278C4.95128 12.9243 0 18.1231 0 24.4566C0 31.0475 5.36217 36.4097 11.9531 36.4097H13.6465V37.3059C13.6465 43.9837 18.6601 49.667 25.3086 50.5258C25.3721 50.5341 25.4361 50.5382 25.5 50.5382C25.5639 50.5382 25.6279 50.5341 25.6914 50.5258C32.3399 49.6669 37.3535 43.9836 37.3535 37.3059V36.4061C37.3778 36.4073 37.4017 36.4098 37.4263 36.4098H39.0469C45.6378 36.4098 51 31.0476 51 24.4567C51 18.1231 46.0487 12.9243 39.8135 12.5277ZM34.3652 37.3059C34.3652 42.4218 30.5677 46.7858 25.5 47.5357C20.4323 46.7858 16.6348 42.4218 16.6348 37.3059V28.5585L25.5 25.4269L34.3652 28.5585V37.3059ZM39.0469 33.4214H37.4263C37.4017 33.4214 37.3778 33.4239 37.3535 33.4251V27.5016C37.3535 26.8683 36.9542 26.3038 36.357 26.0928L25.9976 22.4333C25.6757 22.3195 25.3243 22.3195 25.0024 22.4333L14.643 26.0928C14.0458 26.3037 13.6465 26.8682 13.6465 27.5016V33.4214H11.9531C7.00991 33.4214 2.98828 29.3998 2.98828 24.4566C2.98828 19.5134 7.00991 15.4917 11.9531 15.4917H12.3505C13.0553 15.4917 13.6643 14.9991 13.8116 14.3098C14.9806 8.83841 19.8962 4.86719 25.5 4.86719C31.1038 4.86719 36.0194 8.83841 37.1884 14.3098C37.3357 14.9991 37.9447 15.4917 38.6495 15.4917H39.0469C43.9901 15.4917 48.0117 19.5134 48.0117 24.4566C48.0117 29.3999 43.9901 33.4214 39.0469 33.4214Z"
        fill={color}
      />
      <path
        d="M23.3255 35.5338C22.7406 34.9519 21.7946 34.9539 21.2124 35.5388C20.6303 36.1238 20.6326 37.0698 21.2175 37.6519L23.4696 39.8931C23.761 40.1831 24.1424 40.3282 24.5236 40.3282C24.9048 40.3282 25.2861 40.1831 25.5775 39.8931L29.7813 35.7095C30.3662 35.1274 30.3685 34.1814 29.7864 33.5965C29.2043 33.0115 28.2582 33.0093 27.6734 33.5914L24.5236 36.7261L23.3255 35.5338Z"
        fill={color}
      />
    </svg>
  );
};

export default IconCloudSecurity;
