import React from "react";

const IconAlert = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="52"
      viewBox="0 0 51 52"
      fill="none"
    >
      <g clip-path="url(#clip0_23933_18114)">
        <path
          d="M37.2505 27.3232V23.8605C37.2505 18.3522 33.4617 13.7296 28.3483 12.4545V8.9353C28.3483 7.3595 27.0709 6.08203 25.495 6.08203C23.9192 6.08203 22.6418 7.3595 22.6418 8.9353V12.4544C17.5284 13.7295 13.7396 18.3522 13.7396 23.8604V27.3231C13.7396 31.0252 12.2689 34.5757 9.65119 37.1935C8.50053 38.3442 8.15631 40.0746 8.77901 41.5781C9.4017 43.0815 10.8687 44.0617 12.496 44.0617H19.589C19.589 47.3234 22.2332 49.9676 25.4949 49.9676C28.7567 49.9676 31.4009 47.3234 31.4009 44.0617H38.4939C40.1211 44.0617 41.5882 43.0814 42.2109 41.5781C42.8336 40.0746 42.4893 38.3442 41.3387 37.1935C38.7211 34.5758 37.2505 31.0253 37.2505 27.3232ZM25.495 46.5437C24.1264 46.5437 23.013 45.4302 23.013 44.0617H27.9772C27.9771 45.4303 26.8636 46.5437 25.495 46.5437ZM39.0477 40.2677C38.9546 40.4924 38.7373 40.6377 38.494 40.6377H12.496C12.2527 40.6377 12.0354 40.4926 11.9423 40.2678C11.8491 40.0431 11.9001 39.7867 12.0721 39.6147C15.3553 36.3316 17.1634 31.9664 17.1634 27.3232V23.8605C17.1634 19.2665 20.9009 15.529 25.4949 15.529C30.0889 15.529 33.8265 19.2665 33.8265 23.8605V27.3232C33.8265 31.9663 35.6345 36.3315 38.9177 39.6147C39.0898 39.7867 39.1409 40.043 39.0477 40.2677Z"
          fill="#2A7DE1"
        />
        <path
          d="M9.83046 27.3042C10.7255 26.9994 11.2039 26.0267 10.899 25.1317C9.00906 19.5832 11.9856 13.5316 17.5342 11.6416C18.4292 11.3368 18.9076 10.3641 18.6027 9.46912C18.2978 8.5741 17.3252 8.09544 16.4302 8.40063C9.09455 10.8992 5.15932 18.9001 7.65787 26.2357C7.9004 26.9478 8.56567 27.3961 9.27818 27.3961C9.46136 27.3961 9.64763 27.3665 9.83046 27.3042Z"
          fill="#2A7DE1"
        />
        <path
          d="M14.8459 5.77441C15.7409 5.46957 16.2193 4.49694 15.9145 3.60193C15.6095 2.70692 14.6369 2.22825 13.7419 2.53344C3.15742 6.13849 -2.5207 17.6827 1.08458 28.2673C1.3271 28.9794 1.99237 29.4277 2.70489 29.4277C2.88795 29.4277 3.0741 29.3981 3.25705 29.3358C4.15207 29.0309 4.6305 28.0583 4.32555 27.1633C1.32916 18.366 6.04846 8.77091 14.8459 5.77441Z"
          fill="#2A7DE1"
        />
        <path
          d="M34.5612 8.40057C33.6661 8.0955 32.6936 8.57416 32.3888 9.46906C32.0839 10.3641 32.5624 11.3367 33.4573 11.6415C39.0058 13.5315 41.9824 19.5831 40.0926 25.1317C39.7877 26.0267 40.2661 26.9993 41.1611 27.3042C41.344 27.3665 41.5302 27.396 41.7132 27.396C42.4258 27.396 43.091 26.9476 43.3336 26.2357C45.8322 18.9 41.8969 10.8991 34.5612 8.40057Z"
          fill="#2A7DE1"
        />
        <path
          d="M48.9071 12.779C46.5164 7.91847 42.3761 4.27987 37.2487 2.53344C36.3536 2.22825 35.3811 2.70692 35.0763 3.60193C34.7714 4.49694 35.2499 5.46957 36.1448 5.77441C40.4064 7.22604 43.8477 10.2502 45.8347 14.29C47.8217 18.3299 48.1166 22.9018 46.665 27.1633C46.3601 28.0583 46.8386 29.0309 47.7335 29.3358C47.9164 29.3981 48.1026 29.4277 48.2856 29.4277C48.9982 29.4277 49.6634 28.9792 49.906 28.2673C51.6525 23.1401 51.2978 17.6394 48.9071 12.779Z"
          fill="#2A7DE1"
        />
      </g>
      <defs>
        <clipPath id="clip0_23933_18114">
          <rect
            width="51"
            height="51"
            fill="white"
            transform="translate(0 0.707031)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconAlert;
