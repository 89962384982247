import React from "react";

const IconSecurity = ({
  color = "#2A7DE1",
  backgroundColor = "#fff",
  height = "52",
  width = "51",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 51 52"
      fill="none"
    >
      <g clip-path="url(#clip0_23933_17928)">
        <path
          d="M47.1637 14.5446L47.1622 14.5049C47.14 14.0151 47.1252 13.4968 47.1163 12.9201C47.0746 10.1081 44.8393 7.7821 42.0269 7.6249C36.1632 7.29767 31.627 5.38564 27.7512 1.60789L27.7182 1.57637C26.454 0.41725 24.547 0.41725 23.2825 1.57637L23.2494 1.60789C19.3736 5.38564 14.8375 7.29767 8.97376 7.62529C6.16175 7.7821 3.92599 10.1081 3.88436 12.9205C3.8758 13.4933 3.86062 14.0116 3.83844 14.5049L3.83611 14.5972C3.7221 20.5764 3.58047 28.0179 6.06992 34.7723C7.43877 38.4866 9.51188 41.7153 12.2313 44.3694C15.3285 47.3919 19.3852 49.7915 24.2887 51.5012C24.4482 51.5568 24.6139 51.6019 24.7828 51.6358C25.0209 51.6833 25.2606 51.707 25.5003 51.707C25.74 51.707 25.9801 51.6833 26.2178 51.6358C26.3867 51.6019 26.5536 51.5564 26.7139 51.5004C31.6115 49.7876 35.6639 47.3868 38.7584 44.3647C41.4767 41.7099 43.5498 38.4804 44.9198 34.7653C47.4186 27.9907 47.2774 20.5352 47.1637 14.5446ZM42.1121 33.7299C39.4864 40.8488 34.1266 45.7378 25.7264 48.6755C25.6964 48.6856 25.6645 48.6946 25.6314 48.7012C25.5447 48.7183 25.4563 48.7183 25.3684 48.7008C25.3357 48.6942 25.3038 48.6856 25.2742 48.6755C16.8651 45.7433 11.5017 40.857 8.87804 33.7377C6.58353 27.5117 6.71349 20.6807 6.82827 14.6543L6.82905 14.6209C6.8524 14.103 6.86796 13.5606 6.87652 12.9645C6.8952 11.7159 7.88973 10.6828 9.14068 10.6132C12.4589 10.428 15.374 9.78673 18.0526 8.6529C20.7276 7.52062 23.1035 5.92454 25.3151 3.77361C25.4275 3.67867 25.5735 3.67828 25.6855 3.77361C27.8975 5.92454 30.2734 7.52062 32.948 8.6529C35.6266 9.78673 38.5417 10.428 41.8603 10.6132C43.1113 10.6828 44.1058 11.7159 44.1241 12.9649C44.133 13.5641 44.1486 14.1065 44.172 14.6209C44.2864 20.6542 44.4132 27.4907 42.1121 33.7299Z"
          fill={color}
        />
        <path
          d="M25.499 13.4961C18.4905 13.4961 12.7891 19.1979 12.7891 26.206C12.7891 33.2144 18.4905 38.9163 25.499 38.9163C32.507 38.9163 38.2088 33.2144 38.2088 26.206C38.2088 19.1979 32.507 13.4961 25.499 13.4961ZM25.499 35.9233C20.1407 35.9233 15.7816 31.5643 15.7816 26.206C15.7816 20.8477 20.1407 16.4886 25.499 16.4886C30.8568 16.4886 35.2159 20.8477 35.2159 26.206C35.2159 31.5643 30.8568 35.9233 25.499 35.9233Z"
          fill={color}
        />
        <path
          d="M29.1538 22.2628L23.2504 28.1662L21.6481 26.5639C21.0637 25.9795 20.1162 25.9795 19.5318 26.5639C18.9477 27.1483 18.9477 28.0958 19.5318 28.6798L22.1924 31.3405C22.4846 31.6327 22.8675 31.7786 23.2504 31.7786C23.6333 31.7786 24.0161 31.6327 24.3083 31.3405L31.2701 24.3787C31.8541 23.7943 31.8541 22.8469 31.2701 22.2628C30.6857 21.678 29.7382 21.678 29.1538 22.2628Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_23933_17928">
          <rect
            width={width}
            height={width}
            fill={backgroundColor}
            transform="translate(0 0.707031)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSecurity;
