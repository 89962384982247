import React from "react";

const IconArrow = ({ color = "currentColor", height = "21", width = "20" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M4.16797 10.707H15.0013M10.8346 5.70703L15.2454 10.1178C15.5708 10.4432 15.5708 10.9709 15.2454 11.2963L10.8346 15.707"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default IconArrow;
