import React from "react";

const IconBoundingBox = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="52"
      viewBox="0 0 51 52"
      fill="none"
    >
      <g clip-path="url(#clip0_23933_18101)">
        <path
          d="M30.8229 20.8305C32.4024 19.3857 33.395 17.3093 33.395 15.0051C33.395 10.6514 29.8529 7.10938 25.4992 7.10938C21.1455 7.10938 17.6035 10.6514 17.6035 15.0051C17.6035 17.3094 18.596 19.3857 20.1755 20.8305C14.02 23.0247 9.60156 28.9108 9.60156 35.8102V42.2118C9.60156 43.037 10.2705 43.706 11.0957 43.706H39.9027C40.7279 43.706 41.3969 43.037 41.3969 42.2118V35.8102C41.3969 28.9108 36.9784 23.0247 30.8229 20.8305ZM25.4992 10.0978C28.2052 10.0978 30.4067 12.2992 30.4067 15.0052C30.4067 17.7112 28.2052 19.9126 25.4992 19.9126C22.7932 19.9126 20.5918 17.7111 20.5918 15.0051C20.5918 12.2991 22.7932 10.0978 25.4992 10.0978ZM38.4086 40.7177H12.5898V35.8102C12.5898 28.6919 18.3809 22.9007 25.4992 22.9007C32.6175 22.9007 38.4086 28.6919 38.4086 35.8102V40.7177Z"
          fill="#2A7DE1"
        />
        <path
          d="M7.89574 0.707031H1.49414C0.668976 0.707031 0 1.37601 0 2.20117V8.60277C0 9.42793 0.668976 10.0969 1.49414 10.0969C2.3193 10.0969 2.98828 9.42793 2.98828 8.60277V3.69531H7.89574C8.7209 3.69531 9.38988 3.02634 9.38988 2.20117C9.38988 1.37601 8.7209 0.707031 7.89574 0.707031Z"
          fill="#2A7DE1"
        />
        <path
          d="M7.89574 48.718H2.98828V43.8105C2.98828 42.9854 2.3193 42.3164 1.49414 42.3164C0.668976 42.3164 0 42.9854 0 43.8105V50.2121C0 51.0373 0.668976 51.7063 1.49414 51.7063H7.89574C8.7209 51.7063 9.38988 51.0373 9.38988 50.2121C9.38988 49.387 8.7209 48.718 7.89574 48.718Z"
          fill="#2A7DE1"
        />
        <path
          d="M49.5051 0.707031H43.1035C42.2784 0.707031 41.6094 1.37601 41.6094 2.20117C41.6094 3.02634 42.2784 3.69531 43.1035 3.69531H48.011V8.60277C48.011 9.42793 48.6799 10.0969 49.5051 10.0969C50.3303 10.0969 50.9993 9.42793 50.9993 8.60277V2.20117C50.9993 1.37601 50.3303 0.707031 49.5051 0.707031Z"
          fill="#2A7DE1"
        />
        <path
          d="M49.5051 42.3164C48.6799 42.3164 48.011 42.9854 48.011 43.8105V48.718H43.1035C42.2784 48.718 41.6094 49.387 41.6094 50.2121C41.6094 51.0373 42.2784 51.7063 43.1035 51.7063H49.5051C50.3303 51.7063 50.9993 51.0373 50.9993 50.2121V43.8105C50.9993 42.9854 50.3303 42.3164 49.5051 42.3164Z"
          fill="#2A7DE1"
        />
      </g>
      <defs>
        <clipPath id="clip0_23933_18101">
          <rect
            width="51"
            height="51"
            fill="white"
            transform="translate(0 0.707031)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconBoundingBox;
