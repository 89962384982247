import React from "react";

const IconR500 = ({ color = "#2A7DE1", height = "52", width = "51" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 51 52"
      fill="none"
    >
      <path
        d="M50.3485 30.1207L47.2164 27.9817L48.0862 27.2868C48.8566 26.6714 48.83 25.487 48.0284 24.9082L19.2982 4.16008C18.0078 3.22803 16.216 3.47028 15.2191 4.71112L5.77705 16.4636C4.70326 17.8005 4.97429 19.7703 6.37002 20.7671L13.5479 25.893L10.6662 29.9871C10.1892 30.6647 10.3553 31.6016 11.0356 32.0743L12.0628 32.7877C10.3868 34.405 8.13908 35.32 5.79139 35.32H2.98828V32.0827C2.98828 31.2575 2.3193 30.5885 1.49414 30.5885C0.668977 30.5885 0 31.2575 0 32.0827V47.3229C0 48.1481 0.668977 48.8171 1.49414 48.8171C2.3193 48.8171 2.98828 48.1481 2.98828 47.3229V44.2848H5.01354C10.3767 44.2848 15.6201 42.0293 19.3444 37.8451L20.8389 38.883C21.521 39.3568 22.4588 39.1829 22.9261 38.4969L25.633 34.5232L31.7875 38.9184C32.3307 39.3063 33.0662 39.2871 33.5885 38.8698L35.8184 37.0882L41.6167 41.2615C42.2608 41.7251 43.1544 41.6041 43.6523 40.9871L50.6687 32.293C51.2158 31.6149 51.0687 30.6124 50.3485 30.1207ZM5.01354 41.2964H2.98828V38.3082H5.79129C9.11386 38.3082 12.2856 36.9307 14.5525 34.5168L16.869 36.1257C13.8299 39.387 9.50483 41.2964 5.01354 41.2964ZM21.3051 35.5684L13.9751 30.4776L15.9797 27.6295L23.2005 32.7861L21.3051 35.5684ZM32.6052 35.8303L8.10661 18.3352L17.5487 6.58268L44.6847 26.1795L32.6052 35.8303ZM42.2147 38.0099L38.2427 35.151L44.7745 29.9325L47.3267 31.6756L42.2147 38.0099Z"
        fill={color}
      />
      <path
        d="M37.4551 26.207C38.2803 26.207 38.9492 25.5381 38.9492 24.7129C38.9492 23.8877 38.2803 23.2188 37.4551 23.2188C36.6299 23.2188 35.9609 23.8877 35.9609 24.7129C35.9609 25.5381 36.6299 26.207 37.4551 26.207Z"
        fill={color}
      />
    </svg>
  );
};

export default IconR500;
