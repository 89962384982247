import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";

import { styled } from "linaria/react";
import React from "react";
import profilePic1 from "./assets/testi-1.png";
import profilePic2 from "./assets/testi-2.png";
import profilePic3 from "./assets/testi-3.png";
import profilePic4 from "./assets/testi-4.png";
import profilePic5 from "./assets/testi-5.png";
import profilePic6 from "./assets/testi-6.png";
import IconQuote from "components/common/icon-components/IconQuote";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  width: 100%;
`;

const QuoteBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2.5rem;
  gap: 1.25rem;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
  background: var(--white);
  border-radius: 0.75rem;
  width: 100%;
  max-width: 830px;
`;

const Quote = styled.q`
  color: var(--blue-900);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.033px;
`;

const profilePics = [
  profilePic1,
  profilePic2,
  profilePic3,
  profilePic4,
  profilePic5,
  profilePic6,
];

const QuoteSection = () => {
  return (
    <SectionContainer style={{ background: "#F6F8F9" }}>
      <SectionInner>
        <ContentContainer>
          <QuoteBox>
            <IconQuote />
            <Quote>
              Everything about Rhombus just works. We don&apos;t have to spend a
              ton of time or energy setting up or using the product. It&apos;s
              made my life easier and has given my end-users the ability to do
              their job even better.
            </Quote>
            <div>
              <h4>Daniel Ruiz</h4>
              <p>Network System Administrator, Praesum Healthcare</p>
            </div>
          </QuoteBox>
        </ContentContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default QuoteSection;
